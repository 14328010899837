import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { getImageStyles } from '../utils/utils';
import style from './index.module.css';

const IndexPage = ({ data }) => {
  const imgStyles = getImageStyles(data.contentfulHome.image.fixed.aspectRatio);

  return (
    <Layout>
      <SEO />
      <div className={style.container}>
        <Img
          style={imgStyles}
          imgStyle={imgStyles}
          fixed={data.contentfulHome.image.fixed}
          loading="eager"
        />
      </div>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    contentfulHome: PropTypes.shape({
      image: PropTypes.shape({
        fixed: PropTypes.shape({
          aspectRatio: PropTypes.number.isRequired,
          base64: PropTypes.string.isRequired,
          src: PropTypes.string.isRequired,
          srcSet: PropTypes.string.isRequired,
          width: PropTypes.number.isRequired,
          height: PropTypes.number.isRequired,
        }),
      }).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  {
    contentfulHome {
      image {
        fixed(height: 1500, quality: 65) {
          ...GatsbyContentfulFixed
          aspectRatio
        }
      }
    }
  }
`;

export default IndexPage;
